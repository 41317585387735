import React from "react";

export interface ICheckboxProps {
    value: string,
    facetName: string,
    displayValue: string,
    selected: boolean,
    totalCount: number,
    matches?: number,
    toggleCheckbox: (value: string) => void,
}

export const Checkbox = (props: ICheckboxProps) => {
    const { value, displayValue, facetName, selected, matches, totalCount, toggleCheckbox } = props;

    return (
        <div className="fls-checkbox">
            <label className="fls-checkbox__wrapper">
                <input onChange={() => toggleCheckbox(value)}
                    className="fls-checkbox__input"
                    type="checkbox"
                    id={facetName + value}
                    name={value}
                    checked={selected} />
                <span className="fls-checkbox__checkmark"></span>
                <label className="fls-checkbox__label fls-font__xsmall-regular" htmlFor={facetName + value}>
                    {displayValue} <span className="fls-font__xsmall-regular">({matches !== undefined ? matches + '/' : ''}{totalCount})</span>
                </label>
            </label>
        </div>
    )
}
