import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { AllowPortalAccordion } from '../../components/AllowPortalAccordion/AllowPortalAccordion';
import { AuthorizeView, RoleRequirement } from '../../components/AuthorizeView/AuthorizeView';
import { Button } from '../../components/Button/Button';
import { DataTable } from '../../components/DataTable/DataTable';
import { DataTableExtendedLabelAndValue, DataTableType } from '../../components/DataTable/DataTableModels';
import Pagination from '../../components/Pagination/Pagination';
import { RoleTypes } from '../../constants/GenericContants';
import { LoadingSpinner } from '../../foundation/svg/loading-spinner';
import { spyGlassIcon } from '../../foundation/svg/spyglass-icon';
import { useStores } from '../../stores/StoreDistributor';
import { InPageError, InPageErrorProps } from '../InPageError/InPageError';
import { getHeaders } from './AllowPortalUtils';

const inPageError = (error: { title: string; status: string }): InPageErrorProps => {
  const { title, status } = error;
  return {
    errorType: title,
    errorCode: status,
    buttons: [
      {
        type: 'primary',
        name: 'Return home',
        url: '/',
      },
      {
        type: '',
        name: 'Refresh page',
        url: '',
      },
    ],
  };
};

export const AllowPortalPage = observer(() => {
  const { allowPortalStore, userStore } = useStores();
  const tableIndex = 0;
  const paginationMaxLength = 3;
  const dataIsLoaded = (allowPortalStore?.allowPortalData?.rowData?.length ?? 0) > 0;
  const [headers, setHeaders] = React.useState<DataTableExtendedLabelAndValue[]>([]);

  function isFacetsEmpty() {
    return Object.keys(allowPortalStore.facets).length === 0;
  }

  function GetFacetDropdowns() {
    return [
      'ProductLine',
      'EnoviaState',
      'CatalogueState',
      ...Object.keys(allowPortalStore.facets).filter(
        (facetKey) => !['ProductLine', 'EnoviaState', 'CatalogueState'].includes(facetKey)
      ),
    ];
  }

  useEffect(() => {
    allowPortalStore.itemsPerPage = 10;
    const hasQueryParamsBeenSet = allowPortalStore.AreQueryParamsSet();

    allowPortalStore.GetInitialAllowPortalData().then(() => {
      if (hasQueryParamsBeenSet) {
        allowPortalStore.LoadQueryParamsFromUrl();
        allowPortalStore.FetchAllowPortalData();
      }
      setHeaders(getHeaders(userStore));
    });
  }, [allowPortalStore, userStore]);

  // Redraw the page when search string in search box has changed
  useEffect(() => {
    document.title = `Allow Portal - FLS GSC`;
  }, [allowPortalStore.searchString]);

  return (
    <AuthorizeView roles={[RoleTypes.GPCAccess]} roleRequirement={RoleRequirement.Required}>
      <div className='fls-allow-portal'>
        {allowPortalStore.error !== undefined ? <InPageError {...inPageError(allowPortalStore.error)} /> : ''}

        <React.Fragment>
          <section className='fls-sp-detail__heading-section fls-sp-detail__heading-section--short'>
            <section className='fls-sp-search__input-section'>
              <div className='fls-sp-search__input-container'>
                <h1 className='fls-font__title'>Allow Part Portal</h1>
                <div className='fls-sp-search__util-buttons-container'>
                  <div className='fls-text-input'>
                    <div
                      className='fls-text-input__input-container fls-text-input__input-container--main-search'
                      tabIndex={0}
                    >
                      <div className='fls-text-input__icon'>{spyGlassIcon()}</div>
                      <input
                        className='fls-text-input__input-field fls-font__medium-regular'
                        id='fls-text-input-id'
                        autoFocus={false}
                        placeholder='Search for parts'
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                          if (e.key === 'Enter') {
                            allowPortalStore.FetchAllowPortalData();
                          }
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          allowPortalStore.searchString = e.target.value;
                        }}
                        value={allowPortalStore.searchString}
                      />
                    </div>
                    <Button type='primary' text='Search' action={() => allowPortalStore.FetchAllowPortalData()} />
                  </div>
                  <Button
                    type='primary-with-icon-outline'
                    text='Clear Search'
                    action={() => {
                      allowPortalStore.ClearSearch();
                    }}
                  />

                  <Button
                    type='primary-with-icon-outline'
                    text='Add New Part'
                    action={() => {
                      allowPortalStore.AddNewPartToAllowListModal();
                    }}
                  />
                  <Button
                    type='primary-with-icon-outline'
                    text='Add New Parts through Excel'
                    action={() => {
                      allowPortalStore.addNewPartsExcelModal();
                    }}
                  />
                </div>
              </div>
            </section>
          </section>

          <div className='fls-sp-filter__container'>
            <section className='fls-sp-filter__filter-wrapper'>
              {isFacetsEmpty() ? (
                <div className='fls-spinner__background'>
                  <LoadingSpinner />
                </div>
              ) : (
                GetFacetDropdowns().map((facetKey) => (
                  <AllowPortalAccordion
                    key={facetKey}
                    name={allowPortalStore.mapFacetKeyToDisplay(facetKey)}
                    open={allowPortalStore.openFacets.includes(facetKey)}
                    checkBoxData={allowPortalStore.GetFacetDropdownData(facetKey)}
                    toggleDropdown={() => allowPortalStore.toggleFacet(facetKey)}
                  />
                ))
              )}
            </section>
            <div className='fls-allow-portal__center-container'>
              {dataIsLoaded && (
                <>
                  <DataTable
                    tableType={DataTableType.AllowPortal}
                    key={tableIndex}
                    tableTitle={'Allowed Parts'}
                    tableHeaders={headers}
                    tableData={allowPortalStore.allowPortalData}
                    handlers={{
                      deleteCollection: undefined,
                      exportCollection: undefined,
                      renameCollection: undefined,
                      openCollection: undefined,
                    }}
                  />
                  <Pagination
                    currentPage={allowPortalStore.currentPage}
                    lastPage={allowPortalStore.lastPage}
                    maxLength={paginationMaxLength}
                    setCurrentPage={(newCurrentPage) => {
                      allowPortalStore.currentPage = newCurrentPage;
                      allowPortalStore.FetchAllowPortalData();
                    }}
                    itemsPerPage={allowPortalStore.itemsPerPage}
                    setItemsPerPage={(n) => {
                      if (n === 10 || n === 25 || n === 50 || n === 100) {
                        allowPortalStore.updatePageCount(n);
                        allowPortalStore.FetchAllowPortalData();
                      }
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </AuthorizeView>
  );
});
