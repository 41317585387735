import { makeAutoObservable } from 'mobx';
import {
  DeleteCollectionModalContent,
  NewCollectionModalContent,
  RenameCollectionModalContent,
} from '../components/Modal/ModalComponents';
import { Client, SavedSearch, SavedSearchDeletion, SearchRequest } from '../features/ApiClient/ApiClient';
import { InPageErrorProps } from '../features/InPageError/InPageError';
import { facetRequestsToChosenFacets } from '../foundation/methods/facetCategoriesToSearchRequest';
import PartSearchStore from './PartSearchStore';
import { RootStore } from './StoreDistributor';

export default class SavedSearchStore {
  rootStore: RootStore;
  savedSearchesPageErrorConfig: InPageErrorProps | undefined = undefined;
  isSavedSearchesLoading: boolean = false;
  savedSearches: SavedSearch[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  // Action with modal
  public addSavedSearchModal = (searchRequest: SearchRequest) => {
    let userInputSearchName: string;
    let privateSearchEnabled: boolean = false;

    const returnUserInputSearchName = (userInput: string) => {
      userInputSearchName = userInput;
    };

    const returnPrivateSearchEnabled = (isPrivate: boolean) => {
      privateSearchEnabled = isPrivate;
    };

    const modalConfig = {
      title: 'New saved search',
      slotComponent: (
        <NewCollectionModalContent
          inputValue={returnUserInputSearchName}
          setPrivateBoolean={returnPrivateSearchEnabled}
        />
      ),
      buttonConfig: [
        {
          type: 'cancel',
          label: 'Cancel',
          action: () => this.rootStore.mainStore.setModalDisplayState(false),
        },
        {
          type: 'confirm',
          label: 'Save',
          action: () => {
            this.addSavedSearch(userInputSearchName, searchRequest, privateSearchEnabled);
            this.rootStore.mainStore.setModalDisplayState(false);
          },
        },
      ],
    };
    this.rootStore.mainStore.setModalConfig(modalConfig);
    this.rootStore.mainStore.setModalDisplayState(true);
  };

  // Action with modal
  public renameSavedSearchModal = (exportId: string) => {
    let userInputSearchName: string;

    const returnUserInputSearchName = (userInput: string) => {
      userInputSearchName = userInput;
    };

    const modalConfig = {
      title: 'Rename collection',
      slotComponent: <RenameCollectionModalContent inputValue={returnUserInputSearchName} />,
      buttonConfig: [
        {
          type: 'cancel',
          label: 'Cancel',
          action: () => this.rootStore.mainStore.setModalDisplayState(false),
        },
        {
          type: 'confirm',
          label: 'Rename',
          action: () => {
            let editableSearch = this.findSavedSearch(exportId);
            if (editableSearch === undefined) {
              throw new Error('Saved search not found');
            }
            editableSearch.name = userInputSearchName;
            this.updateSavedSearch(editableSearch);
            this.rootStore.mainStore.setModalDisplayState(false);
          },
        },
      ],
    };

    this.rootStore.mainStore.setModalConfig(modalConfig);
    this.rootStore.mainStore.setModalDisplayState(true);
  };

  // Action with modal
  public deleteSavedSearchModal = (searchId: string) => {
    const modalConfig = {
      title: 'Delete collection',
      slotComponent: <DeleteCollectionModalContent />,
      buttonConfig: [
        {
          type: 'cancel',
          label: 'Cancel',
          action: () => this.rootStore.mainStore.setModalDisplayState(false),
        },
        {
          type: 'delete',
          label: 'Delete',
          action: () => {
            this.deleteSavedSearch(searchId);
            this.rootStore.mainStore.setModalDisplayState(false);
          },
        },
      ],
    };

    this.rootStore.mainStore.setModalConfig(modalConfig);
    this.rootStore.mainStore.setModalDisplayState(true);
  };

  // Action
  public getSavedSearches = () => {
    let apiClient = new Client();

    this.isSavedSearchesLoading = true;
    apiClient
      .savedSearchesAll()
      .then((savedSearches) => {
        this.savedSearches = savedSearches;
        this.isSavedSearchesLoading = false;
      })
      .catch((error) => {
        this.savedSearchesPageErrorConfig = {
          errorType: error.title,
          errorCode: error.status,
          buttons: [
            {
              type: 'primary',
              name: 'Return home',
              url: '/',
            },
            {
              type: '',
              name: 'Refresh page',
              url: '',
            },
          ],
        };
        this.isSavedSearchesLoading = false;
      });
  };

  // Action
  public addSavedSearch = (name: string, searchRequest: SearchRequest, privateSearch: boolean) => {
    let apiClient = new Client();

    let newExport = new SavedSearch({
      name: name,
      searchRequest: searchRequest,
      creatorName: undefined,
      creationTimeUtc: null!,
      privateSearch: privateSearch,
    });

    apiClient.savedSearchesPOST(newExport).then(
      () => {
        this.getSavedSearches();
      },
      (reject) => {
        console.log(reject);
      }
    );
  };

  // Action
  public updateSavedSearch = (savedExport: SavedSearch) => {
    let apiClient = new Client();

    apiClient.savedSearchesPOST(savedExport).then(
      () => {
        this.getSavedSearches();
      },
      (reject) => {
        console.log(reject);
      }
    );
  };

  // Action
  public deleteSavedSearch = (searchId: string) => {
    let apiClient = new Client();

    const deletionRequest = new SavedSearchDeletion({
      id: searchId,
    });

    apiClient.savedSearchesDELETE(deletionRequest).then(
      () => {
        this.getSavedSearches();
      },
      (reject) => {
        console.log(reject);
      }
    );
  };

  // Action
  public startExport = (exportId: string) => {
    let exportStore = this.rootStore.exportStore;
    let exportRequest = this.findSavedSearch(exportId);
    exportStore.startExport(exportRequest?.searchRequest!);
  };

  // Action
  public openSavedSearch = (searchId: string) => {
    const a = this.getSavedSearchUrl(searchId);

    if (a != null) {
      window.location.href = a;
    }
  };

  private findSavedSearch = (searchId: string): SavedSearch | undefined => {
    return this.savedSearches.find((exprt) => exprt.id === searchId);
  };

  public getSavedSearchUrl = (searchId: string): string | undefined => {
    const savedSearch = this.findSavedSearch(searchId);

    if (savedSearch?.searchRequest !== undefined) {
      const searchRequest = savedSearch.searchRequest;

      const chosenFacets = facetRequestsToChosenFacets(searchRequest.facetRequests ?? []);

      const params = PartSearchStore.getSearchQueryParams(
        searchRequest.itemsPerPage ?? 10,
        searchRequest.pageNumber ?? 1,
        searchRequest.searchString ?? '',
        chosenFacets
      );

      return 'filter?' + params.toString();
    } else {
      return undefined;
    }
  };
}
