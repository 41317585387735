import { InPageError } from "../features/InPageError/InPageError.tsx";
import { LandingPage } from "../features/LandingPage/LandingPage.page.tsx";
import { SparePartSavedSearchesPage } from "../features/SparePartCollection/SparePartSavedSearches.page.tsx";
import { SparePartDetailPage } from "../features/SparePartDetail/SparePartDetail.page.tsx";
import { SparePartFilterPage } from "../features/SparePartFilter/SparePartFilter.page.tsx";
import { SystemDetailsPage } from "../features/SystemDetails/SystemDetails.page";
import { AllowPortalPage } from "../features/AllowPortal/AllowPortal.page";
import { CustomerOverviewpage } from "../features/PricingAgreements/CustomerOverview/CustomerOverview.page"
import { PartOverviewpage } from "../features/PricingAgreements/PartOverview/PartOverview.page";

const inPageErrorConfig = {
    errorType: "Not found",
    errorCode: "404",
    buttons: [
        {
            type: "primary",
            name: "Return home",
            url: "/",
        },
        {
            type: "",
            name: "Refresh page",
            url: "",
        }
    ]
}

const AppRoutes = [
    {
        path: "/",
        index: true,
        element: <LandingPage />,
    }, {
        path: '/PricingAgreements/CustomerOverview',
        element: <CustomerOverviewpage />,
    }, {
        name: "Filter",
        path: '/filter',
        element: <SparePartFilterPage />,
    }, {
        path: '/part/*',
        element: <SparePartDetailPage />,
    }, {
        name: "Saved searches",
        path: '/savedSearches',
        element: <SparePartSavedSearchesPage />,
    }, {
        path: '*',
        element: <InPageError {...inPageErrorConfig} />,
    }, {
        path: "/system-details",
        element: <SystemDetailsPage />,
    }, {
        path: "/allow-portal",
        element: <AllowPortalPage />,
    }, {
        path: '/PricingAgreements/PartOverview/:customerId',
        element: <PartOverviewpage />,
    }
]

export default AppRoutes
