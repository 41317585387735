import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { DataTable } from "../../components/DataTable/DataTable";
import { LoadingSpinner } from "../../foundation/svg/loading-spinner";
import { Client, ITableElement } from "../ApiClient/ApiClient";

export const SystemDetailsPage = observer(() => {

    const [buildInfo, setBuildInfo] = useState<ITableElement | undefined | null>(undefined);

    useEffect(() => {
        document.title = `System Details - FLS GSC`
        setBuildInfo(null);

        let apiClient = new Client();
        apiClient.systemBuildInfo()
            .then(info => {
                setBuildInfo(info);
            }, reject => {
                console.error(reject);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    return (<div className="system-details">

        <section className="system-details-title-band-section">
            <section className="system-details-title-section">
                <div className="system-detials-title-container">
                    <h1 className="fls-font__title">System Details</h1>
                </div>
            </section>
        </section>

        <section className="system-details-content-section">
            <article>                    

                {buildInfo === null && <div className="fls-spinner__background">
                    <LoadingSpinner />
                </div>}

                {!!buildInfo && <DataTable
                    key={"key"}
                    tableTitle={""}
                    tableHeaders={buildInfo.tableHeader ?? []}
                    tableData={buildInfo?.tableData![0]!}
                    handlers={{
                        deleteCollection: undefined,
                        exportCollection: undefined,
                        renameCollection: undefined,
                        openCollection: undefined,
                    }}
                />}
            </article>
        </section>

    </div>);
});
