export enum ApiCallState {
  idle,
  loading,
  success,
  error,
}

export interface ApiState<T> {
  state: ApiCallState;
  result?: T;
  error?: string;
}
