import React from 'react';
import { useStores } from '../../stores/StoreDistributor';

export enum RoleRequirement {
  Required,
  Restricted,
}

interface IAuthorizeViewProps {
  roles: string[];
  roleRequirement: RoleRequirement;
  children: JSX.Element;
}

const ShouldShowElement = (
  roles: string[],
  userRoles: string[] | null,
  roleRequirement: RoleRequirement,
  children: JSX.Element
): JSX.Element => {
  const userHasRole = (role: string) => userRoles?.includes(role);
  const shouldShowElementForRequired = () => roles.every(userHasRole);
  const shouldShowElementForRestricted = () => roles.some((role) => !userHasRole(role));
  const viewToDisplay = () => <React.Fragment>{children}</React.Fragment>;

  switch (roleRequirement) {
    case RoleRequirement.Required:
      if (shouldShowElementForRequired()) {
        return viewToDisplay();
      }
      break;
    case RoleRequirement.Restricted:
      if (shouldShowElementForRestricted()) {
        return viewToDisplay();
      }
      break;
    default:
      return <React.Fragment />;
  }
  return <React.Fragment />;
};

export const AuthorizeView = ({ roles, roleRequirement, children }: IAuthorizeViewProps) => {
  const { userStore } = useStores();
  const userRoles = userStore.getUserRoles();

  if (!userRoles && roleRequirement !== RoleRequirement.Restricted) {
    return <React.Fragment />;
  }

  return ShouldShowElement(roles, userRoles, roleRequirement, children);
};
