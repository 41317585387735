import { CellData, ILabelAndValue } from '../../features/ApiClient/ApiClient';

export interface ITableCellHandlers {
  deleteCollection: ((collectionId: string) => void) | undefined;
  renameCollection: ((collectionId: string) => void) | undefined;
  exportCollection: ((collectionId: string) => void) | undefined;
  openCollection: ((collectionId: string) => void) | undefined;
}

export interface IDataTableProps<T> {
  tableTitle: string;
  tableHeaders: ILabelAndValue[];
  tableData: ITableData<T>;
  handlers: ITableCellHandlers;
  tableType?: DataTableType;
}

export interface ITableData<T> {
  location?: string | undefined;
  rowData?: Array<T>;
}
export class TableData<T> implements ITableData<T> {
  public location?: string | undefined;
  public rowData?: Array<T>;

  constructor(location?: string, rowData?: Array<T>) {
    this.location = location;
    this.rowData = rowData || [];
  }

  addRow(row: T): void {
    if (!this.rowData) {
      this.rowData = [];
    }
    this.rowData.push(row);
  }
}

export interface ITableCell<T> {
  tableCell: T | ILink;
  collectionId: string;
  handlers: ITableCellHandlers;
}

export interface ILink {
  value: string;
  url: string;
}

export interface ITableCellButtonModalProps {
  tableCellData: ITableCellButton;
  collectionId: string;
  handlers: ITableCellHandlers;
}

export interface ITableCellButton {
  name: Action;
  options?: Array<{ name: Action }>;
}

export type Action = 'delete' | 'edit' | 'rename' | 'export' | 'open';

export enum DataTableType {
  Empty = '',
  AllowPortal = 'AllowPortal',
}
export enum DataCellType {
  Text = '',
  Dropdown = 'Dropdown',
  Quotable = 'Quotable',
  Checkbox = 'Checkbox',
  RestrictedCommentField = 'RestrictedCommentField',
}

export interface ExtendedCellData extends CellData {
  value?: string | undefined;
  cellType?: DataCellType;
  dropdownOptions?: string[];
  checkboxValue?: boolean;
  commentFieldPlaceholder?: string;
  partNumber?: string | undefined;
  callBack?: Function | undefined;
  hidden?: boolean;
}
export enum DataTableSortDirection {
  None = '',
  Default = 'default',
  Ascending = 'ascending',
  Descending = 'descending',
}

export interface DataTableExtendedLabelAndValue extends ILabelAndValue {
  sortDirection?: DataTableSortDirection | undefined;
  restrictedCommentField?: boolean | undefined;
  hidden?: boolean | undefined;
}
