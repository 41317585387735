import { createBrowserHistory } from 'history';
import { makeAutoObservable } from "mobx";
import { syncHistoryWithStore } from 'mobx-react-router';
import { Client, IPCCustomerPartDetails } from "../features/ApiClient/ApiClient";
import { RootStore } from "./StoreDistributor";

export default class CustomerPartDetailStore {
    rootStore: RootStore;

    private static readonly pageSizeQueryKey: string = "pageSize";
    private static readonly pageNumberQueryKey: string = "pageNumber";

    currentPage: number = 1;
    itemsPerPage: number = 10;
    totalCustomers: number = 0;
    customerId: string = "";
    customerPartResult: undefined | IPCCustomerPartDetails | null = undefined;
    error: { title: string, status: string } | undefined = undefined;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.fetchSearchParamsFromUrl();
    }

    get getLastPage(): number {
        return Math.ceil(this.totalCustomers / this.itemsPerPage);
    }

    public updateCustomerPartFetch = (): Promise<void> => {
        let apiClient = new Client();
        this.customerPartResult = null;

        return apiClient.pricingAgreement(this.customerId, this.itemsPerPage, this.currentPage)
            .then((customerPart: IPCCustomerPartDetails) => {
                this.customerPartResult = customerPart;
                this.totalCustomers = customerPart.totalCount || 0
            })
            .catch(error => {
                this.customerPartResult = undefined;
                this.error = {
                    title: error.title,
                    status: error.status,
                };
            });
    }

    public resetCurrentPage = () => {
        this.currentPage = 1;
    }

    public setSearchParamsInUrl = () => {
        const currentUrl = new URL(window.location.href);
        const params = new URLSearchParams(currentUrl.search);
        params.set(CustomerPartDetailStore.pageSizeQueryKey, this.itemsPerPage.toString());
        params.set(CustomerPartDetailStore.pageNumberQueryKey, this.currentPage.toString());

        const browserHistory = createBrowserHistory();
        const history = syncHistoryWithStore(browserHistory, this.rootStore.routerStore);
        history.replace({
            search: params.toString(),
            pathname: currentUrl.pathname,
            hash: currentUrl.hash
        });
    }

    public fetchSearchParamsFromUrl = () => {
        const url = new URL(window.location.toString());
        const params = url.searchParams;
        const itemsPerPage = params.get(CustomerPartDetailStore.pageSizeQueryKey);
        const currentPage = params.get(CustomerPartDetailStore.pageNumberQueryKey);
        if (itemsPerPage && currentPage) {
            this.itemsPerPage = parseInt(itemsPerPage);
            this.currentPage = parseInt(currentPage);
        }
        else {
            this.itemsPerPage = 10;
            this.currentPage = 1;
        }
        const path = url.pathname;
        const pathSegments = path.split('/');
        const customerId = pathSegments[3];
        if (customerId) {
            this.customerId = customerId;
        }
    }
}
