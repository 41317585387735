import { DataTableExtendedLabelAndValue, DataTableSortDirection } from '../../components/DataTable/DataTableModels';
import { RoleTypes } from '../../constants/GenericContants';
import UserStore from '../../stores/UserStore';

export function getHeaders(userStore: UserStore): DataTableExtendedLabelAndValue[] {
  return [
    { label: 'Part Number', value: 'PartNumber', textAlignRight: false },
    { label: 'Catalogue State', value: 'CatalogueState', textAlignRight: false },
    { label: 'Enovia State', value: 'EnoviaState', textAlignRight: false },
    { label: 'Level', value: 'Level', textAlignRight: false },
    { label: 'Part Pricing Category', value: 'PartPricingCategory', textAlignRight: false },
    { label: 'Quotable', value: 'Quotable', textAlignRight: false },
    { label: 'Product Line', value: 'ProductLine', textAlignRight: false },
    { label: 'Alternative Part Number', value: 'AlternativePartNumber', textAlignRight: false },
    { label: 'Source From Aurora', value: 'SourceFromAurora', textAlignRight: false },
    {
      label: 'Comment',
      value: 'Comment',
      textAlignRight: false,
      restrictedCommentField: true,
      hidden: !userStore.getUserRoles()?.includes(RoleTypes.SPCAdmin),
    },
    { label: 'Created', value: 'Created', textAlignRight: false, sortDirection: DataTableSortDirection.Default },
    {
      label: 'Last Modified',
      value: 'LastModified',
      textAlignRight: false,
      sortDirection: DataTableSortDirection.Default,
    },
  ] as DataTableExtendedLabelAndValue[];
}
