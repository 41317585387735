import { createBrowserHistory } from 'history';
import { makeAutoObservable } from "mobx";
import { syncHistoryWithStore } from 'mobx-react-router';
import { Client, IPCCustomerDetails } from "../features/ApiClient/ApiClient";
import { RootStore } from "./StoreDistributor";


export default class CustomerDetailStore {
    rootStore: RootStore;

    private static readonly pageSizeQueryKey: string = "pageSize";
    private static readonly pageNumberQueryKey: string = "pageNumber";


    currentPage: number = 1;    // Starting from page 1
    itemsPerPage: number = 10;  // Default amount of items per page
    totalCustomers: number = 0; // Total number of customers
    customerResult: undefined | IPCCustomerDetails | null = undefined; // Undefined: not loaded, Null: loading, else: loaded
    error: { title: string, status: string } | undefined = undefined;

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        makeAutoObservable(this);
        this.fetchSearchParamsFromUrl();
    }


    get getLastPage(): number {
        return Math.ceil(this.totalCustomers / this.itemsPerPage);
    }

    public updateCustomerFetch = (): Promise<void> => {
        let apiClient = new Client();
        this.customerResult = null;

        return apiClient.getCustomerData(this.itemsPerPage, this.currentPage)
            .then((customerResult: IPCCustomerDetails) => {
                this.customerResult = customerResult;
                this.totalCustomers = customerResult.totalCount || 0
            })
            .catch(error => {
                this.customerResult = undefined;
                this.error = {
                    title: error.title,
                    status: error.status,
                };
            });
    }

    public resetCurrentPage = () => {
        this.currentPage = 1;
    }

    public setSearchParamsInUrl = () => {
        const params = new URLSearchParams();
        params.append(CustomerDetailStore.pageSizeQueryKey, this.itemsPerPage.toString());
        params.append(CustomerDetailStore.pageNumberQueryKey, this.currentPage.toString());

        const browserHistory = createBrowserHistory();
        const history = syncHistoryWithStore(browserHistory, this.rootStore.routerStore);
        history.replace({
            search: params.toString(),
        });
    }

    public fetchSearchParamsFromUrl = () => {
        const url = new URL(window.location.toString());
        const params = url.searchParams;
        const itemsPerPage = params.get(CustomerDetailStore.pageSizeQueryKey);
        const currentPage = params.get(CustomerDetailStore.pageNumberQueryKey);
        if (itemsPerPage && currentPage) {
            this.itemsPerPage = parseInt(itemsPerPage);
            this.currentPage = parseInt(currentPage);
        }
        else {
            this.itemsPerPage = 10;
            this.currentPage = 1;
        }
    }
}
