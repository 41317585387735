import { makeAutoObservable } from 'mobx';
import { NewLocaleSettingsModalContent } from '../components/Modal/ModalComponents';
import { Client, LocaleSettings } from '../features/ApiClient/ApiClient';
import { InPageErrorProps } from '../features/InPageError/InPageError';
import { RootStore } from './StoreDistributor';

export default class LocaleSettingsStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }
  LocaleSettingsErrorConfig: InPageErrorProps | undefined = undefined;

  localeSettings: LocaleSettings = new LocaleSettings({
    decimalSeparator: '.',
    thousandSeparator: ',',
    dateFormatString: 'YYYY-MM-DD',
    localeTimeZoneName: 'Romance Standard Time',
  });

  // Action with modal
  public addLocaleSettingsModal = () => {
    this.getLocaleSettings().then(() => {
      let thousandSeparator = this.localeSettings.thousandSeparator ? this.localeSettings.thousandSeparator : ',';
      let decimalSeparator = this.localeSettings.decimalSeparator ? this.localeSettings.decimalSeparator : '.';
      let dateFormat = this.localeSettings.dateFormatString ? this.localeSettings.dateFormatString : 'YYYY-MM-DD';
      let timeZone = this.localeSettings.localeTimeZoneName
        ? this.localeSettings.localeTimeZoneName
        : 'Romance Standard Time';

      const modalConfig = {
        title: 'Personal Settings',
        slotComponent: (
          <NewLocaleSettingsModalContent
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            dateFormat={dateFormat}
            timeZone={timeZone}
            onSelectChanged={(selectThousand, selectDecimal, selectZone, selectFormat) => {
              if (selectThousand) {
                thousandSeparator = selectThousand;
              }
              if (selectDecimal) {
                decimalSeparator = selectDecimal;
              }
              if (selectZone) {
                timeZone = selectZone;
              }
              if (selectFormat) {
                dateFormat = selectFormat;
              }
            }}
          />
        ),
        buttonConfig: [
          {
            type: 'cancel',
            label: 'Cancel',
            action: () => this.rootStore.mainStore.setModalDisplayState(false),
          },
          {
            type: 'confirm',
            label: 'Save',
            action: () => {
              this.addLocaleSettings(thousandSeparator, decimalSeparator, dateFormat, timeZone);
              this.rootStore.mainStore.setModalDisplayState(false);
              window.location.reload();
            },
          },
        ],
      };
      this.rootStore.mainStore.setModalConfig(modalConfig);
      this.rootStore.mainStore.setModalDisplayState(true);
    });
  };

  // Action
  public addLocaleSettings = (
    thousandSeperator: string,
    decimalSeparator: string,
    dateFormat: string,
    timeZoneName: string
  ) => {
    let apiClient = new Client();

    let newLocaleSettings = new LocaleSettings({
      thousandSeparator: thousandSeperator,
      decimalSeparator: decimalSeparator,
      dateFormatString: dateFormat,
      localeTimeZoneName: timeZoneName,
    });

    apiClient.localeSettingsPOST(newLocaleSettings).then(
      () => {},
      (reject) => {
        console.log(reject);
      }
    );
  };

  // Action
  public getLocaleSettings = () => {
    let apiClient = new Client();

    return apiClient
      .localeSettingsGET(undefined)
      .then((localeSettings) => {
        this.localeSettings = localeSettings;
      })
      .catch((error) => {
        this.LocaleSettingsErrorConfig = {
          errorType: error.title,
          errorCode: error.status,
          buttons: [
            {
              type: 'primary',
              name: 'Return home',
              url: '/',
            },
            {
              type: '',
              name: 'Refresh page',
              url: '',
            },
          ],
        };
      });
  };
}
