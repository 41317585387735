import { observer } from 'mobx-react';
import React from 'react';
import { Button } from '../../../components/Button/Button';
import { useStores } from '../../../stores/StoreDistributor';

export const ExcelUploadModal = observer(() => {
  const { allowPortalStore } = useStores();
  const [file, setFile] = React.useState<File>();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const excel = event.target.files?.[0];
    setFile(excel);
    allowPortalStore.setExcelFile(excel);
  };

  return (
    <div className='fls-allowportal-excel-modal'>
      <Button
        type='primary-with-icon-outline'
        text='Download Template'
        action={() => window.open('../../AllowList_MassUpdate_Template.xlsx', '_blank')}
      />
      <div className='fls-allowportal-excel-modal__upload-area'>
        {' '}
        <Button type='primary-with-icon-outline' text='Select file' action={() => fileInputRef.current!.click()} />
        <span>{file?.name}</span>
        <input
          onChange={handleFileChange}
          multiple={false}
          ref={fileInputRef}
          type='file'
          hidden
          accept='.xlsx, .xls, .csv'
        />
      </div>
    </div>
  );
});
