import { observer } from 'mobx-react';
import { LoadingSpinner } from '../../../foundation/svg/loading-spinner';
import { useStores } from '../../../stores/StoreDistributor';
import { ApiCallState } from '../../ApiClient/ApiHelperTypes';

export const ExcelUploadResultModal = observer(() => {
  const { allowPortalStore } = useStores();
  if (allowPortalStore.excelUploadResult.state === ApiCallState.loading) {
    return (
      <div className='fls-allow-portal-modal__excel-result__spinner'>
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div className='fls-allow-portal-modal__excel-result fls-font__medium-bold'>
      <div className='fls-allow-portal-modal__excel-result__line'>
        <label className='fls-allow-portal-modal__excel-result__line__label'>Update part(s)</label>
        <label className='fls-allow-portal-modal__excel-result__line__label'>
          {allowPortalStore.excelUploadResult.result?.updatedPartNumbers?.length}
        </label>
      </div>
      <div className='fls-allow-portal-modal__excel-result__line '>
        <label className='fls-allow-portal-modal__excel-result__line__label'>Created part(s)</label>
        <label className='fls-allow-portal-modal__excel-result__line__label'>
          {allowPortalStore.excelUploadResult.result?.createdPartNumbers?.length}
        </label>
      </div>
    </div>
  );
});
