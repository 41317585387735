import React, { useEffect, useState } from 'react';
import { spyGlassIcon } from '../../../foundation/svg/spyglass-icon';
import { Button } from '../../Button/Button';

interface IProps {
  placeholder: string;
  callBack: Function;
  autoFocus: boolean;
  type: string;
  label?: string;
  textInputType?: TextInputType;
  onBlur?: Function;
  additionalInfo?: any;
  defaultValue?: string;
}

export const TextInputType = {
  Text: '' as const,
  RestrictedCommentFieldAllowPortal: 'RestrictedCommentFieldAllowPortal' as const,
  AllowPortalAddPart: 'AllowPortalAddPart' as const,
};
export type TextInputType = (typeof TextInputType)[keyof typeof TextInputType];

export const TextInput = (props: IProps) => {
  const { placeholder, callBack, autoFocus, type, label, textInputType, onBlur, defaultValue } = props;

  // Initialize inputValue with defaultValue
  const [inputValue, setInputValue] = useState(defaultValue || '');

  // Optional: Sync defaultValue with inputValue if defaultValue changes
  useEffect(() => {
    if (defaultValue !== undefined) {
      setInputValue(defaultValue);
    }
  }, [defaultValue]);

  const tableTypeClassname =
    textInputType === TextInputType.RestrictedCommentFieldAllowPortal
      ? 'fls-text-input__allow-portal-restricted'
      : textInputType === TextInputType.AllowPortalAddPart
        ? 'fls-text-input__allow-portal-add-part'
        : '';

  const handleOnBlur = (event: React.FormEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
    if (onBlur === undefined) {
      return;
    } else if (textInputType === TextInputType.RestrictedCommentFieldAllowPortal) {
      onBlur(event.currentTarget.value, props.additionalInfo);
    } else if (type !== 'main-search') {
      onBlur(event.currentTarget.value);
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value);
    if (type !== 'main-search' && textInputType !== TextInputType.RestrictedCommentFieldAllowPortal) {
      callBack(event.currentTarget.value);
    }
  };

  const inputTypeModifierClass = (type: string) => {
    return `fls-text-input__input-container--${type}`;
  };

  return (
    <div className={`fls-text-input ${tableTypeClassname}`}>
      <div className={'fls-text-input__input-container ' + inputTypeModifierClass(type)} tabIndex={0}>
        {type === 'main-search' && <div className='fls-text-input__icon'>{spyGlassIcon()}</div>}
        {type !== 'main-search' && (
          <label className='fls-font__xsmall-regular' htmlFor='fls-text-input-id'>
            {label ?? ''}
          </label>
        )}
        <input
          className='fls-text-input__input-field fls-font__medium-regular'
          id='fls-text-input-id'
          autoFocus={autoFocus}
          placeholder={placeholder}
          onKeyPress={(e) => (e.key === 'Enter' ? callBack(inputValue) : null)}
          onInput={(e) => handleChange(e)}
          onBlur={(e) => handleOnBlur(e)}
          value={inputValue}
        />
      </div>
      {type === 'main-search' && <Button type='primary' text='Search' action={callBack.bind(this, inputValue)} />}
    </div>
  );
};
