import React from 'react'
import { chevron } from '../../foundation/svg/chevron'
import { Checkbox, ICheckboxProps } from '../InputFields/Checkbox/Checkbox'

export interface IAllowPortalAccordionProps {
    name: string,
    open: boolean,
    checkBoxData: ICheckboxProps[],
    toggleDropdown: () => void,
}

export const AllowPortalAccordion = (props: IAllowPortalAccordionProps) => {
    const { name, open, checkBoxData, toggleDropdown } = props

    const selectedClass = () => {
        return open ? 'fls-allow-portal-accordion__dropdown-toggle--selected' : ''
    }

    return (<React.Fragment>
        <button className={"fls-font__medium-regular fls-allow-portal-accordion__dropdown-toggle " + selectedClass()} onClick={() => toggleDropdown()}>
            <div className="fls-allow-portal-accordion__icon-container">{chevron()}</div>
            <span>{name}</span>
        </button>
        <ul className={"fls-allow-portal-accordion__dropdown-item-container " + (open ? '' : 'fls-allow-portal-accordion__dropdown-item-container--hidden')}>
            {checkBoxData.map((checkboxProps: ICheckboxProps, index: number) => {
                return (<li className="fls-allow-portal-accordion__dropdown-item" key={index}>
                    <Checkbox {...checkboxProps}/>
                </li>)
            })}
        </ul>
    </React.Fragment>);
}
