import { makeAutoObservable } from 'mobx';
import { NominatePartModalContent, SpcFeedbackModalContent } from '../components/Modal/ModalComponents';
import { AddAllowListPartRequest, Client } from '../features/ApiClient/ApiClient';
import { RootStore } from './StoreDistributor';

export default class DetailStore {
  rootStore: RootStore;
  addPartNumber: string = '';

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.addPartNumber = '';
    makeAutoObservable(this);
  }

  TriggerOutlook = () => {
    const spcAdminEmail = 'SPCTeams@flsmidth.com';
    const mailSubject = 'Adding Parts to Global Service Catalogue';
    const emailbody =
      'Hello,\r\n\nTo nominate part(s) to be added to the Global Service Catalogue, please provide the full list of parts with the corresponding Product ' +
      "lines using the 'PART ADDITION TEMPLATE' file.\r\nPlease be aware that only standard parts will be considered. Standard parts are parts which:\r\n  1. Appear in Enovia\r\n  " +
      '2. Require no additional engineering after initial procurement\r\n  3. Can be sourced from a single supplier\r\n  4. Have an active sales history or expected ' +
      'active sales forecast.\r\n\r\nThank you for your request!\r\n\r\n';
    window.open(
      'mailto:' + spcAdminEmail + '?subject=' + mailSubject + '&body=' + encodeURIComponent(emailbody),
      '_self'
    );
  };

  CreateNewAllowListPart() {
    let apiClient = new Client();
    const request = new AddAllowListPartRequest();
    request.partNumber = this.addPartNumber;
    apiClient.addAllowListPart(request);
  }

  public addFeedbackModal = () => {
    const modalConfig = {
      title: 'Provide Feedback',
      slotComponent: <SpcFeedbackModalContent />,
      buttonConfig: [
        {
          type: 'action',
          label: 'Share your Feedback',
          action: () => window.open('https://forms.office.com/e/pt0snb0HbV', '_blank', 'noopener'),
        },
      ],
    };
    this.rootStore.mainStore.setModalConfig(modalConfig);
    this.rootStore.mainStore.setModalDisplayState(true);
  };

  public addNominatePartModal = () => {
    const modalConfig = {
      title: 'Nominate to GSC ?',
      slotComponent: <NominatePartModalContent />,
      buttonConfig: [
        {
          type: 'action',
          class: 'fls-button fls-button--primary-with-icon-outline',
          label: 'Submit Parts',
          action: () => this.TriggerOutlook(),
        },
        {
          type: 'action',
          label: 'Download Template',
          action: () => window.open('../../Part_Addition_Template.xlsx', '_blank'),
        },
      ],
    };
    this.rootStore.mainStore.setModalConfig(modalConfig);
    this.rootStore.mainStore.setModalDisplayState(true);
  };
}
