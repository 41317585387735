import { useEffect } from 'react';
import { useStores } from '../../stores/StoreDistributor';
import { Button } from '../Button/Button';

interface IButtonConfig {
  type: string;
  label: string;
  className?: string;
  action: () => void;
}

export const ModalType = {
  Default: '' as const,
  AllowPortalModal: 'allowPortalModal' as const,
};
export type ModalType = (typeof ModalType)[keyof typeof ModalType];

export interface IModalRootWrapperProps {
  title: string;
  slotComponent: JSX.Element;
  buttonConfig: IButtonConfig[];
  modalType?: ModalType;
  modalClosedCallback?: () => void;
}

export const ModalRootWrapper = (props: IModalRootWrapperProps) => {
  const { title, slotComponent, buttonConfig, modalType, modalClosedCallback } = props;
  const { mainStore } = useStores();

  const closeModal = () => {
    if (modalClosedCallback != null) {
      modalClosedCallback();
    } else {
      mainStore.setModalDisplayState(false);
    }
  };

  const handleKeyUpEvent = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      closeModal();
    }
  };

  const displayModal = () => {
    if (modalType === ModalType.AllowPortalModal) {
      return (
        <div className='fls-modal'>
          <div className='fls-modal__background' onClick={closeModal} />
          <article className='fls-modal__content fls-modal__content--allow-portal'>
            <div className='fls-modal__header--allow-portal'>
              <h2 className='fls-font__xsmall-title--allow-portal'>{title}</h2>
            </div>
            <div className='fls-modal__slot-component fls-modal__slot-component--allow-portal'>
              <div className='fls-modal__slot-container fls-modal__slot-container--allow-portal'>{slotComponent}</div>
            </div>
            <div className='fls-modal__action-buttons fls-modal__action-buttons--allow-portal'>
              {buttonConfig.map((button, index) => {
                return (
                  button.action && (
                    <Button
                      key={index}
                      type={button.type}
                      text={button.label}
                      className={button.className}
                      action={button.action.bind(this, false)}
                    />
                  )
                );
              })}
            </div>
          </article>
        </div>
      );
    } else if (modalType === ModalType.Default || modalType === undefined) {
      return (
        <div className='fls-modal'>
          <div className='fls-modal__background' onClick={closeModal}></div>
          <article className='fls-modal__content'>
            <div className='fls-modal__header'>
              <h2 className='fls-font__xsmall-title'>{title}</h2>
              <button className='fls-button__modal-close' onClick={closeModal}>
                <span aria-hidden='true'>×</span>
              </button>
            </div>
            <div className='fls-modal__slot-component'>{slotComponent}</div>
            <div className='fls-modal__action-buttons'>
              {buttonConfig.map((button, index) => {
                return (
                  button.action && (
                    <Button
                      key={index}
                      type={button.type}
                      text={button.label}
                      action={button.action.bind(this, false)}
                    />
                  )
                );
              })}
            </div>
          </article>
        </div>
      );
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUpEvent);
  }, []);

  if (mainStore.modalDisplayState) {
    return displayModal();
  } else {
    return <template></template>;
  }
};
