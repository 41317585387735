import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { AuthorizeView, RoleRequirement } from '../../../components/AuthorizeView/AuthorizeView';
import { Button } from '../../../components/Button/Button';
import Pagination from '../../../components/Pagination/Pagination';
import { RoleTypes } from '../../../constants/GenericContants';
import { LoadingSpinner } from '../../../foundation/svg/loading-spinner';
import { useStores } from '../../../stores/StoreDistributor';
import { Client, CustomerDetails, IPCCustomerDetails } from '../../ApiClient/ApiClient';
import { CustomerDataTable } from './CustomerDataTable';

type PossibleItemsPerPage = 10 | 25 | 50 | 100;

export const CustomerOverviewpage = () => {
  const [addnewcustopen, setAddnewcustOpen] = React.useState(false);
  const [delcustopen, setDelcustOpen] = React.useState(false);
  const [value, setValue] = React.useState<string | undefined>('');
  const [localSPDetailData, setLocalSPDetailData] = React.useState<IPCCustomerDetails | undefined | null>();

  const [customerNo, setCustomerNo] = React.useState<string>('');
  const [contractdate, setContractDate] = React.useState<string>('');
  const [currency, setCurrency] = React.useState<string>('');

  const [dateError, setDateError] = React.useState<string>('');
  const [cusDataLoading, setCusDataLoading] = React.useState<boolean>(false);

  const { CustomerDetailStore } = useStores();
  const paginationMaxLength = 3;

  const setItemsPerPage = (n: PossibleItemsPerPage) => {
    CustomerDetailStore.itemsPerPage = n;
    CustomerDetailStore.currentPage = 1;
  };
  async function loadData() {
    setCusDataLoading(true);
    try {
      await CustomerDetailStore.updateCustomerFetch();
      setLocalSPDetailData(CustomerDetailStore.customerResult);
    } catch (error) {
      setLocalSPDetailData(null);
    } finally {
      setCusDataLoading(false);
    }
  }

  React.useEffect(() => {
    CustomerDetailStore.fetchSearchParamsFromUrl();
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFormValid = (): boolean => {
    return customerNo.trim() !== '' && currency.trim() !== '' && contractdate.trim() !== '';
  };

  async function addNewCustomer() {
    let apiClient = new Client();
    const customerdetails = new CustomerDetails();
    if (isFormValid()) {
      customerdetails.customerId = customerNo.trim();
      customerdetails.contractEndDate = contractdate.trim();
      customerdetails.currency = currency.trim();
      setCusDataLoading(true);
      setAddnewcustOpen(false);
      await apiClient.createCustomer(customerdetails);
      await loadData();
      setCusDataLoading(false);
    }
  }

  async function handleDelCustomer(value: string | undefined): Promise<void> {
    let apiClient = new Client();
    const customerDtl = new CustomerDetails();
    customerDtl.customerId = value?.trim();
    setCusDataLoading(true);
    setDelcustOpen(false);
    await apiClient.deleteCustomer(customerDtl);
    const isLastItemOnPage =
      CustomerDetailStore.totalCustomers - 1 <=
      (CustomerDetailStore.currentPage - 1) * CustomerDetailStore.itemsPerPage;
    if (isLastItemOnPage && CustomerDetailStore.currentPage > 1) {
      CustomerDetailStore.currentPage = CustomerDetailStore.currentPage - 1;
      CustomerDetailStore.setSearchParamsInUrl();
    }
    await loadData();
    setCusDataLoading(false);
  }

  function handleClickDelCustomerOpen(customerId: string | undefined): void {
    setDelcustOpen(true);
    setValue(customerId);
  }

  // Function to handle key down and block non-alphabetic characters
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const allowedKeys = /^[a-zA-Z]$/;
    if (!allowedKeys.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
      e.preventDefault();
    }
  };
  function addnewcustCloseDialog() {
    setAddnewcustOpen(false);
    setCustomerNo('');
    setContractDate('');
    setCurrency('');
    setDateError('');
  }

  function handleContractDateChange(date: string) {
    setDateError('');
    const todayDate = new Date();
    const inputDate = new Date(date);
    const formattedDate =
      ('0' + inputDate.getDate()).slice(-2) +
      '-' +
      ('0' + (inputDate.getMonth() + 1)).slice(-2) +
      '-' +
      inputDate.getFullYear();

    todayDate.setHours(0, 0, 0, 0);
    inputDate.setHours(0, 0, 0, 0);

    if (inputDate >= todayDate) {
      setContractDate(formattedDate);
    } else {
      setContractDate('');
      setDateError('Please select a future date');
    }
  }

  const custDetails =
    localSPDetailData && localSPDetailData?.customerDetailList && localSPDetailData?.customerDetailList?.length > 0;

  return (
    <AuthorizeView roles={[RoleTypes.SPCAdmin]} roleRequirement={RoleRequirement.Required}>
      <div className='fls-sp-collection'>
        <div>
          <Dialog
            open={delcustopen}
            onClose={() => setDelcustOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
          >
            <DialogTitle className='fls-model-PC fls-model-PC__header'>
              <span className='fls-model-PC fls-model-PC__header'>Do you wish to delete {value} ? </span>
            </DialogTitle>
            <DialogActions>
              <button className='fls-model-PC fls-model-PC--delete' onClick={() => setDelcustOpen(false)}>
                <span>No</span>
              </button>
              <button className='fls-model-PC fls-model-PC--primary' onClick={() => handleDelCustomer(value)}>
                <span>Yes</span>
              </button>
            </DialogActions>
          </Dialog>
        </div>

        {/*CUSTOMER ADD*/}

        <div>
          <Dialog
            open={addnewcustopen}
            onClose={() => setAddnewcustOpen(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
          >
            <DialogTitle className='fls-model-PC fls-model-PC__header'>
              <span className='fls-model-PC fls-model-PC__header'>Add new Customer</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    Customer Id
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    autoFocus={false}
                    placeholder={'Enter Customer Id'}
                    onChange={(e) => setCustomerNo(e.target.value)}
                  />
                </div>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    Currency
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    type='text'
                    maxLength={3}
                    autoFocus={false}
                    placeholder={'Enter Currency'}
                    onChange={(e) => setCurrency(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <br />
                <div className='fls-text-input-PC__input-container-PC fls-text-input-PC__input-container--regular'>
                  <label
                    className='fls-text-input-PC__label-field-PC fls-text-input-PC__center fls-font__medium-bold'
                    htmlFor='fls-text-input-id'
                  >
                    Contract End Date
                  </label>
                  <input
                    className='fls-text-input-PC__input-field-PC fls-font__medium-regular'
                    id='fls-text-input-id'
                    type='date'
                    autoFocus={false}
                    placeholder={'Enter Contract End Date'}
                    onChange={(e) => handleContractDateChange(e.target.value)}
                  />
                </div>
                <br />
                {dateError && (
                  <div className='fls-text-input-PC__label-field-PC'>
                    <span className='fls-part-upload-error-text'>{dateError}</span>
                  </div>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className='fls-model-PC fls-model-PC--delete' onClick={() => addnewcustCloseDialog()}>
                <span>Cancel</span>
              </button>
              <button
                className='fls-model-PC fls-model-PC--primary'
                disabled={!isFormValid()}
                onClick={() => addNewCustomer()}
              >
                <span>Add new Customer</span>
              </button>
            </DialogActions>
          </Dialog>
        </div>

        <section className='fls-sp-detail__heading-section'>
          <div className='fls-sp-detail__heading-wrapper'>
            <h1 className='fls-font__title'>Pricing Agreement Customer Overview</h1>
          </div>
          <br />
          <div className='fls-sp-detail__heading-wrapper'>
            <Button
              type='fls-button fls-button--primary'
              text='Add new Customer'
              action={() => setAddnewcustOpen(true)}
            ></Button>
          </div>
        </section>
        {cusDataLoading ? (
          <div className='fls-spinner__background'>
            <LoadingSpinner />
          </div>
        ) : custDetails ? (
          <>
            <CustomerDataTable data={localSPDetailData} deleteHandler={handleClickDelCustomerOpen} />
            <div className='container'>
              <Pagination
                currentPage={CustomerDetailStore.currentPage}
                lastPage={CustomerDetailStore.getLastPage}
                maxLength={paginationMaxLength}
                setCurrentPage={(newCurrentPage) => {
                  CustomerDetailStore.currentPage = newCurrentPage;
                  loadData();
                  CustomerDetailStore.setSearchParamsInUrl();
                }}
                itemsPerPage={CustomerDetailStore.itemsPerPage}
                setItemsPerPage={(n) => {
                  if (n === 10 || n === 25 || n === 50 || n === 100) {
                    setItemsPerPage(n);
                    CustomerDetailStore.setSearchParamsInUrl();
                  }
                }}
              />
            </div>
          </>
        ) : (
          <div className='fls-no-data-found'>
            <span className='fls-part-upload-download-template-text' style={{ fontSize: '2rem' }}>
              No customer details have been found!
            </span>
          </div>
        )}
      </div>
    </AuthorizeView>
  );
};
