import { RouterStore } from 'mobx-react-router';
import * as React from 'react';
import AllowPortalStore from './AllowPortalStore';
import CustomerDetailStore from './CustomerDetailsStore';
import CustomerPartDetailStore from './CustomerPartDetailStore';
import DetailStore from './DetailStore';
import ExportStore from './ExportStore';
import LocaleSettingsStore from './LocaleSettingsStore';
import MainStore from './MainStore';
import PartSearchStore from './PartSearchStore';
import SavedSearchStore from './SavedSearchStore';
import UserStore from './UserStore';

// RootStore encapsulates other store to allow for store-to-store comminication
export class RootStore {
  mainStore = new MainStore();
  routerStore = new RouterStore();
  userStore = new UserStore(this);
  CustomerPartDetailStore = new CustomerPartDetailStore(this);
  CustomerDetailStore = new CustomerDetailStore(this);
  partSearchStore = new PartSearchStore(this);
  savedSearchesStore = new SavedSearchStore(this);
  exportStore = new ExportStore(this);
  detailStore = new DetailStore(this);
  localeSettingsStore = new LocaleSettingsStore(this);
  allowPortalStore = new AllowPortalStore(this);
}

export const storesContext = React.createContext(new RootStore());

export const useStores = () => React.useContext(storesContext);
