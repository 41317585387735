import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { DataTable } from '../../components/DataTable/DataTable'
import { LoadingSpinner } from '../../foundation/svg/loading-spinner'
import SavedSearchStore  from '../../stores/SavedSearchStore'
import { useStores } from '../../stores/StoreDistributor'
import { SavedSearch } from '../ApiClient/ApiClient'
import { InPageError } from '../InPageError/InPageError'

const toShortIsoDate = (date: Date) =>
    date.toISOString().slice(0, 10);

const createTableData = (searches: SavedSearch[], savedSearchesStore: SavedSearchStore) => {
    return {
        tableTitle: "",
        tableHeader: [{
            label: "Name",
            value: "name",
        }, {
            label: "Saved by",
            value: "uploadedBy",
        }, {
            label: "Date saved",
            value: "dateOfUpload",
        }, {
            label: "Type",
            value: "saveType",
        }],
        tableData: [{
            location: undefined,
            rowData: searches.map((savedSearch, _i) => ({
                uuid: savedSearch.id,
                name: savedSearch.name
                    ? {
                        value: savedSearch.name,
                        url: savedSearchesStore.getSavedSearchUrl(savedSearch.id ?? ""),
                    }
                    : "-",
                uploadedBy: savedSearch.creatorName ?? "-",
                dateOfUpload: savedSearch.creationTimeUtc ? toShortIsoDate(savedSearch.creationTimeUtc) : "-",
                saveType: savedSearch.privateSearch ? "Private" : "Public",
                buttons: [
                    /*{ Button commented out to remove Export functionality in accordance with issue FLSSPC-346.
                        name: "export",
                    }*/,
                    {
                        name: "edit",
                        options: [
                            {
                                name: "rename",
                            },
                            {
                                name: "delete",
                            }
                        ]
                    }
                ]
            }))
        }]
    }
}

export const SparePartSavedSearchesPage = observer(() => {
    const { savedSearchesStore } = useStores();

    useEffect(() => {
        document.title = `FLS GSC`;
        savedSearchesStore.getSavedSearches();
    }, [])

    const tableData = createTableData(savedSearchesStore.savedSearches, savedSearchesStore);

    return (<div className="fls-sp-collection">
        <section className="fls-sp-detail__heading-section">
            {!savedSearchesStore.savedSearchesPageErrorConfig &&
                <div className="fls-sp-detail__heading-wrapper">
                    <h1 className="fls-font__title">Saved searches</h1>
                    <p className="fls-font__small-regular">On this page you can see and edit your saved searches from your prior searches and filters. Note that you can also see your colleagues saved searches here.</p>
                </div>}
        </section>

        {savedSearchesStore.savedSearchesPageErrorConfig ?
            <InPageError {...savedSearchesStore.savedSearchesPageErrorConfig} />
            :
            <section className="fls-sp-collection__content-section">
                {savedSearchesStore.isSavedSearchesLoading ?
                    <div className="fls-spinner__background">
                        <LoadingSpinner />
                    </div>
                    :
                    tableData ?
                        <DataTable
                            tableTitle={tableData.tableTitle}
                            tableHeaders={tableData.tableHeader}
                            // Take the first table data because TableData class contains data for more than one table
                            tableData={tableData.tableData ? tableData.tableData[0] : {}}
                            handlers={{
                                deleteCollection: (id) => { savedSearchesStore.deleteSavedSearchModal(id); },
                                renameCollection: (id) => { savedSearchesStore.renameSavedSearchModal(id); },
                                exportCollection: (id) => { savedSearchesStore.startExport(id); },
                                openCollection: (id) => { savedSearchesStore.openSavedSearch(id); },
                            }}
                        />
                        :
                        <div className="fls-sp-detail__table-error-msg fls-font__medium-bold fls-font__error">
                            There are no saved searches at this time
                        </div>
                }

            </section>
        }
    </div>);
})
