import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Accordion, IAccordionProps } from '../../components/Accordion/Accordion';
import { Button } from '../../components/Button/Button';
import { ExternalLinks } from '../../components/ExternalLinks/ExternalLinks';
import { ICheckboxProps } from '../../components/InputFields/Checkbox/Checkbox';
import { SearchTextInput } from '../../components/InputFields/SearchTextInput/SearchTextInput';
import Pagination from '../../components/Pagination/Pagination';
import { SparePartCard } from '../../components/SparePartCard/SparePartCard';
import { facetCategoriesToSearchRequest } from '../../foundation/methods/facetCategoriesToSearchRequest';
import { AddIcon } from '../../foundation/svg/add-icon';
import { LoadingSpinner } from '../../foundation/svg/loading-spinner';
import { FacetCategoryDisplay, FacetDropdown, FacetValueSelector } from '../../stores/FacetStore';
import { useStores } from '../../stores/StoreDistributor';
import { InPageError, InPageErrorProps } from '../InPageError/InPageError';

type PossibleItemsPerPage = 10 | 25 | 50 | 100;

const inPageError = (error: { title: string; status: string }): InPageErrorProps => {
  const { title, status } = error;
  return {
    errorType: title,
    errorCode: status,
    buttons: [
      {
        type: 'primary',
        name: 'Return home',
        url: '/',
      },
      {
        type: '',
        name: 'Refresh page',
        url: '',
      },
    ],
  };
};

export const SparePartFilterPage = observer(() => {
  const { savedSearchesStore, partSearchStore } = useStores();

  const location = useLocation();
  const paginationMaxLength = 3;

  // Ensure we only use the correct page sizes
  const setItemsPerPage = (n: PossibleItemsPerPage) => {
    partSearchStore.itemsPerPage = n;
  };

  const navigate = useNavigate();
  const updatePartSearchStore = () => {
    partSearchStore.updatePartSearch().then(() => {
      if (partSearchStore.searchResult?.indexes?.length === 1 && partSearchStore.searchResult.exactMatch) {
        navigate(`/part/${partSearchStore.searchResult.indexes[0].partNumber}`);
      }
    });
  };

  useEffect(() => {
    setItemsPerPage(10);

    if (!partSearchStore.isFacetsReady) {
      partSearchStore.updatePartFacets().then(() => {
        // Read from URL
        if (location.search !== '') {
          partSearchStore.fetchSearchParamsFromUrl();
          updatePartSearchStore();
        }

        // Read from search store
        if (partSearchStore.searchString !== '') {
          updatePartSearchStore();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Redraw the page when search string in search box has changed
  useEffect(() => {
    document.title = `Filter - FLS GSC`;
  }, [partSearchStore.searchString]);

  return (
    <div className='fls-sp-filter'>
      {partSearchStore.error !== undefined ? <InPageError {...inPageError(partSearchStore.error)} /> : ''}

      <React.Fragment>
        <section className='fls-sp-detail__heading-section fls-sp-detail__heading-section--short'>
          <section className='fls-sp-search__input-section'>
            <div className='fls-sp-search__input-container'>
              <h1 className='fls-font__title'>Sparepart Filter</h1>
              <div className='fls-sp-search__util-buttons-container'>
                <SearchTextInput
                  searchStore={partSearchStore}
                  activationCallback={() => {
                    partSearchStore.resetCurrentPage();
                    updatePartSearchStore();
                  }}
                  placeholder='Search standard part'
                  autoFocus={true}
                />
                {
                  <Button
                    type='primary-with-icon-outline'
                    text='Clear Search'
                    action={() => {
                      partSearchStore.facetStore?.setAllUnselected();
                      partSearchStore.searchString = '';
                      partSearchStore.resetCurrentPage();
                      partSearchStore.updatePartSearch();
                    }}
                  />
                }
                <Button
                  disabled={!partSearchStore.searchResult?.indexes?.length}
                  type='primary-with-icon-outline'
                  icon={AddIcon()}
                  text='Save this search'
                  action={() => {
                    if (!partSearchStore.facetStore) {
                      return;
                    }
                    const searchRequest = facetCategoriesToSearchRequest(
                      partSearchStore.searchString,
                      partSearchStore.facetStore.facetCategories
                    );
                    savedSearchesStore.addSavedSearchModal(searchRequest);
                  }}
                />
              </div>
            </div>
          </section>
        </section>

        <div className='fls-sp-filter__container'>
          <section className='fls-sp-filter__filter-wrapper'>
            {partSearchStore.facetStore?.facetCategories === undefined ? (
              <div className='fls-spinner__background'>
                <LoadingSpinner />
              </div>
            ) : (
              partSearchStore.facetStore.facetCategories?.map((category: FacetCategoryDisplay, index: number) => {
                return (
                  <article key={index} className='fls-sp-filter__filter-type-container'>
                    <h3 className='fls-font__xsmall-title'>{category.name}</h3>
                    <ul>
                      {category.facetDropdowns?.map((facet: FacetDropdown, index: number) => {
                        const toggleDropdown = () => {
                          partSearchStore.toggleFacetDisplay(facet.name ?? '');
                        };

                        const toggleCheckbox = (selectedCheckbox: string) => {
                          partSearchStore.toggleFacetValueCheckbox(selectedCheckbox, facet.name ?? '');
                        };

                        const selectedMarker = facet.facets.some((f) => f.selected) ? ' *' : '';

                        const accordionProps: IAccordionProps = {
                          name: facet.label + selectedMarker ?? '',
                          open: partSearchStore.isFacetOpen(facet.name ?? ''),
                          checkBoxData: (facet.facets ?? []).map((facetValue: FacetValueSelector) => {
                            let checkboxProps: ICheckboxProps = {
                              value: facetValue.value ?? '',
                              facetName: facet.name ?? '',
                              displayValue: facetValue.label ?? '',
                              selected: facetValue.selected ?? true,
                              matches: facetValue.matches,
                              totalCount: facetValue.totalCount,
                              toggleCheckbox: toggleCheckbox,
                            };
                            return checkboxProps;
                          }),
                          facetCategory: category.name ?? '',
                          toggleDropdown: toggleDropdown,
                        };
                        return (
                          <li key={index}>
                            <Accordion {...accordionProps} />
                          </li>
                        );
                      })}
                    </ul>
                  </article>
                );
              })
            )}
          </section>

          {partSearchStore.searchResultIsLoading ? (
            <div className='fls-spinner__background'>
              <LoadingSpinner />
            </div>
          ) : partSearchStore.searchResult?.indexes?.length ? (
            <div className='fls-sp-search__card-wrapper'>
              {partSearchStore.searchResult?.indexes?.map((sparePartIndex, i) => (
                <SparePartCard {...sparePartIndex} key={i} />
              ))}

              <div className='container'>
                <Pagination
                  currentPage={partSearchStore.currentPage}
                  lastPage={partSearchStore.getLastPage}
                  maxLength={paginationMaxLength}
                  setCurrentPage={(newCurrentPage) => {
                    partSearchStore.currentPage = newCurrentPage;
                    updatePartSearchStore();
                    partSearchStore.setSearchParamsInUrl();
                  }}
                  itemsPerPage={partSearchStore.itemsPerPage}
                  setItemsPerPage={(n) => {
                    if (n === 10 || n === 25 || n === 50 || n === 100) {
                      setItemsPerPage(n);
                      updatePartSearchStore();
                      partSearchStore.setSearchParamsInUrl();
                    }
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className='fls-sp-detail__table-info-msg fls-font__medium-bold fls-font__error'>
                No results for this search
              </div>
              {partSearchStore.searchResult?.externalLinks?.gpsturl && partSearchStore.searchString ? (
                <div>
                  <ExternalLinks {...partSearchStore.searchResult?.externalLinks} />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          )}
        </div>
      </React.Fragment>
    </div>
  );
});
