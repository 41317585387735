import { makeAutoObservable } from 'mobx';
import { msalInstance } from '../Auth';
import { RootStore } from './StoreDistributor';

export default class UserStore {
  rootStore: RootStore;
  userRoles: string[] | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }
  getUserRoles = (): string[] | null => {
    //@ts-ignore
    const clientSettings = window.clientSettings;
    const account = msalInstance(clientSettings).publicClientApplication.getAllAccounts()[0];
    this.userRoles = account?.idTokenClaims?.roles || null;
    return this.userRoles;
  };
}
