import { observer } from 'mobx-react';

interface IButtonProps {
  disabled?: boolean;
  type: string;
  icon?: JSX.Element;
  text: string;
  action: Function;
  className?: string;
}

export const Button = observer(({ type, icon, text, action, className, disabled }: IButtonProps) => {
  return (
    <button disabled={disabled} className={`fls-button fls-button--${type} ${className}`} onClick={() => action()}>
      {icon ? <div className='fls-button__icon-container'>{icon}</div> : null}
      <span>{text}</span>
    </button>
  );
});
