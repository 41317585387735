import React, { ReactNode, useRef, useState } from 'react';

export const SortableDefaultSVG = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" style={{ marginLeft: '5px' }}fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.77396 23.6803C5.7995 23.7058 5.82632 23.7299 5.85425 23.7528C5.86663 23.7631 5.87985 23.772 5.8926 23.7816C5.90825 23.7933 5.92358 23.8055 5.93985 23.8163C5.95532 23.8267 5.97149 23.8357 5.98733 23.8452C6.00196 23.8539 6.01621 23.8632 6.03125 23.8712C6.04761 23.8799 6.06425 23.8873 6.08094 23.8951C6.0966 23.9025 6.11197 23.9103 6.128 23.917C6.14422 23.9237 6.16068 23.9291 6.17718 23.9349C6.19433 23.9411 6.2113 23.9477 6.22874 23.953C6.24524 23.9579 6.26183 23.9617 6.27847 23.9658C6.29638 23.9704 6.3141 23.9753 6.33238 23.979C6.3515 23.9827 6.37063 23.985 6.38989 23.9878C6.40588 23.99 6.42168 23.9931 6.4378 23.9947C6.47366 23.9981 6.50957 24 6.54552 24C6.58147 24 6.61738 23.9982 6.65314 23.9946C6.66936 23.993 6.68507 23.99 6.70105 23.9877C6.72032 23.985 6.73944 23.9827 6.75857 23.9789C6.7768 23.9753 6.79457 23.9702 6.81247 23.9657C6.82911 23.9616 6.84571 23.958 6.86221 23.9529C6.87964 23.9476 6.89661 23.9411 6.91377 23.9349C6.93027 23.929 6.94672 23.9236 6.96294 23.9169C6.97902 23.9102 6.99444 23.9025 7.01 23.895C7.02664 23.8873 7.04333 23.8799 7.05969 23.8711C7.07474 23.8631 7.08899 23.8539 7.10361 23.8452C7.11955 23.8357 7.13563 23.8267 7.1511 23.8163C7.16746 23.8054 7.18279 23.7932 7.19835 23.7815C7.21114 23.7719 7.22432 23.763 7.23669 23.7528C7.26463 23.7299 7.29144 23.7057 7.31699 23.6803L12.7713 18.2258C13.1974 17.7999 13.1974 17.1091 12.7713 16.6831C12.3454 16.257 11.6546 16.257 11.2286 16.6831L7.63644 20.2755V1.09092C7.63644 0.488438 7.148 0 6.54552 0C5.94303 0 5.4546 0.488438 5.4546 1.09092V20.2755L1.86229 16.6832C1.43633 16.2572 0.745535 16.2572 0.319535 16.6832C-0.106512 17.1092 -0.106512 17.7999 0.319535 18.226L5.77396 23.6803Z" fill="white"/>
            <path d="M18.146 0.247219C18.1337 0.237094 18.1208 0.228375 18.1082 0.218906C18.0922 0.207 18.0767 0.194766 18.0603 0.183703C18.045 0.173437 18.029 0.164578 18.0133 0.155203C17.9985 0.146344 17.984 0.137016 17.9687 0.128859C17.9526 0.120281 17.9361 0.113016 17.9196 0.105234C17.9038 0.0977344 17.8882 0.0898125 17.872 0.0831094C17.856 0.0765 17.8397 0.0711094 17.8235 0.0653438C17.8062 0.0591094 17.7889 0.0524063 17.7712 0.0471094C17.755 0.0422344 17.7386 0.0386719 17.7223 0.0345469C17.7041 0.0299531 17.6861 0.0248906 17.6676 0.0211875C17.6489 0.0174844 17.63 0.0152812 17.6112 0.0126094C17.5949 0.0102187 17.5787 0.00717187 17.5621 0.00548438C17.5274 0.0020625 17.4926 0.000328125 17.4578 0.000234375C17.4567 0.000140625 17.4558 0 17.4546 0C17.4535 0 17.4525 0.000140625 17.4513 0.000140625C17.4165 0.00028125 17.3817 0.00196875 17.347 0.00539062C17.3304 0.00707813 17.3142 0.010125 17.2979 0.0125156C17.279 0.0151875 17.2601 0.0173906 17.2415 0.0210937C17.223 0.02475 17.205 0.0298125 17.1868 0.0344531C17.1705 0.0385781 17.1542 0.0421406 17.1379 0.0470156C17.1202 0.0524063 17.103 0.0590156 17.0856 0.06525C17.0694 0.0710625 17.0531 0.0763594 17.0371 0.0830156C17.0208 0.0897188 17.0052 0.0976406 16.9895 0.105141C16.973 0.112828 16.9565 0.120141 16.9405 0.128766C16.9251 0.136922 16.9106 0.146203 16.8958 0.155109C16.8801 0.164484 16.8641 0.173344 16.8488 0.183609C16.8323 0.194672 16.8169 0.206859 16.801 0.218813C16.7884 0.228281 16.7754 0.237 16.7631 0.247125C16.7352 0.270047 16.7084 0.294187 16.6828 0.319641L11.2286 5.77402C10.8025 6.19997 10.8025 6.89077 11.2286 7.31677C11.6545 7.74281 12.3453 7.74281 12.7713 7.31677L16.3637 3.72464V22.9091C16.3637 23.5116 16.8521 24 17.4546 24C18.0571 24 18.5455 23.5116 18.5455 22.9091V3.72464L22.1379 7.31686C22.3508 7.52986 22.63 7.63636 22.9092 7.63636C23.1884 7.63636 23.4676 7.52991 23.6805 7.31681C24.1066 6.89086 24.1066 6.20006 23.6805 5.77406L18.2263 0.319688C18.2007 0.294234 18.1739 0.270094 18.146 0.247219Z" fill="white"/>
        </svg>
    );
}
